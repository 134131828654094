@import '../css/media.scss';
@import '../css/reset.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
.final {
    font-family: "roboto";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(143, 141, 141, 0.5); /* Slightly dark background */
    animation: fadeIn 0.6s ease-in-out;
  }
  
  /* Blur effect for the background */
  .blur {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Darker overlay for focus */
    backdrop-filter: blur(5px); /* Smooth blur effect */
  }
  
  /* Modal content styling */
  .content-final {
    position: relative;
    background-color: #fff; /* White background for contrast */
    @include xs{
        padding: 15px;
    }
    padding: 30px;
    border-radius: 15px; /* Rounded corners for a modern look */
    width: 90%;
    max-width: 500px;
    z-index: 10;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
    text-align: center;
    overflow: hidden;
    animation: slideUp 0.6s ease-out; /* Smooth slide-up animation */
    backdrop-filter: blur(10px); /* Light blur effect on background */
  }
  
  /* Title styling */
  .group .a {
    font-size: 24px;
    font-weight: bold;
    color: #f39c12; /* Golden color for emphasis */
    text-transform: uppercase;
    margin-bottom: 15px;
    letter-spacing: 2px; /* Spacing for better readability */
  }
  
  /* Gift message styling */
  .group span {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: bold;
    color: #e74c3c; /* Bright red for excitement */
    background: linear-gradient(45deg, #ff6347, #ff1493); /* Gradient effect */
    -webkit-background-clip: text;
    background-clip: text;
    padding: 0 5px;
    display: inline-block;
    margin-top: 5px;
  }
  
  /* Additional message styling */
  .group p {
    font-size: 18px;
    color: #333; /* Standard text color */
    margin-top: 20px;
  }
  
  /* Close button styling */
  .btn-off {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 32px;
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;
   
   
    padding: 5px;
  }
  
  .btn-off:hover {
    color: #e74c3c; /* Red color on hover */
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Slide-up animation */
  @keyframes slideUp {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  