@import '../css/media.scss';
@import '../css/reset.scss';

.modal-quay {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .vongquay-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        .arrow-modal {
            position: absolute;
            z-index: 1;
            top: -1%;
            margin-left: 5px;
            @include xs {
                height: 20%;
            }
            @include sm {
                height: 20%;
            }
            @include md {
                height: 20%;
            }
            @include lg {
                // width: 50px;
                // height: 60px;
                height: 20%;
            }
            @include xl {
                height: 15%;
            }
        }
        .vongquay-modal {
            @include xs {
                height: 45vh;
                @media (orientation: landscape) {
                    height: 80vh;
                }
            }
            @include sm {
                height: 50vh;
                @media (orientation: landscape) {
                    height: 80vh;
                }
            }
            @include md {
                height: 62vh;
                @media (orientation: landscape) {
                    height: 90vh;
                }
            }
            @include lg {
                width: 65vh;
                height: 65vh;
                @media (orientation: landscape) {
                    height: 70vh;
                    width: 70vh;
                }
            }
        }
        .btXoay {
            position: absolute;
            cursor: pointer;
            opacity: 0;
            .anh_btn-quay {
                @include xs {
                    //height: 55px;
                    width: 65px;
                    object-fit: cover;
                    &:hover {
                        transform: scale(1.3);
                        transition: 0.5s;
                    }
                }
                @include sm {
                    //height: 70px;
                    width: 70px;
                    object-fit: cover;
                    &:hover {
                        transform: scale(1.3);
                        transition: 0.5s;
                    }
                }
                @include md {
                    //height: 80px;
                    width: 80px;
                    object-fit: cover;
                    &:hover {
                        transform: scale(1.3);
                        transition: 0.5s;
                    }
                }
                @include lg {
                    //height: 70px;
                    width: 70px;
                    object-fit: cover;
                    &:hover {
                        transform: scale(1.3);
                        transition: 0.5s;
                    }
                }
            }
        }
    }
    .btn-cancel {
        color: #333;
        @include xs {
            position: absolute;
            font-size: 20px;
            top: 5px;
            right: 4px;
            cursor: pointer;
        }
        @include sm {
            position: absolute;
            font-size: 40px;
            top: 5px;
            right: 4px;
            cursor: pointer;
        }
    }
}
