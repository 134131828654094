@import '../css/media.scss';
@import '../css/reset.scss';
.btn-wheel {
    .content_btn {
        .anh-btn {
            @include xs {
                width: 40px;
                height: 40px;
            }
            @include sm {
                width: 50px;
                height: 50px;
            }
            @include lg {
                width: 60px;
                height: 60px;
            }
        }
        z-index: 2;
        position: fixed;
        border-radius: 50%;
        cursor: pointer;
        animation: ring 3s 1s ease-in-out infinite;

        @include xs {
            bottom: 5px;
            right: 5px;
        }
        @include sm {
            bottom: 5px;
            right: 5px;
        }
        @include lg {
            bottom: 0;
            right: 10%;
        }
        @include xl {
            bottom: 0;
            right: 19%;
        }
    }

    .blur {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #9b9999;
    }
    .modall {
        z-index: 10;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
@keyframes ring {
    0% {
        transform: rotate(0);
    }
    5% {
        transform: rotate(30deg);
    }
    10% {
        transform: rotate(-28deg);
    }
    15% {
        transform: rotate(34deg);
    }
    20% {
        transform: rotate(-32deg);
    }
    25% {
        transform: rotate(30deg);
    }
    30% {
        transform: rotate(-28deg);
    }
    35% {
        transform: rotate(26deg);
    }
    40% {
        transform: rotate(-24deg);
    }
    45% {
        transform: rotate(22deg);
    }
    50% {
        transform: rotate(-20deg);
    }
    55% {
        transform: rotate(18deg);
    }
    60% {
        transform: rotate(-16deg);
    }
    65% {
        transform: rotate(14deg);
    }
    70% {
        transform: rotate(-12deg);
    }
    75% {
        transform: rotate(10deg);
    }
    80% {
        transform: rotate(-8deg);
    }
    85% {
        transform: rotate(6deg);
    }
    90% {
        transform: rotate(-4deg);
    }
    95% {
        transform: rotate(2deg);
    }
    100% {
        transform: rotate(-1deg);
    }
}
