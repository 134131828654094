@import '../css/media.scss';
@import '../css/reset.scss';
/* Modal container */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(143, 141, 141, 0.5);
  animation: fadeIn 0.5s ease-in-out;
}

/* Modal overlay blur effect */
.blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

/* Modal content */
.content {
  position: relative;
  background-color: white;
  @include xs{
    padding: 15px;
  }
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  z-index: 10;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  overflow: hidden;
  animation: slideUp 0.6s ease-out;
}

/* Modal background image (optional) */
.anh-ketqua {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

/* Button to close modal */
.btn-cancel {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

.btn-cancel:hover {
  color: #e74c3c;
}

/* Main result text container */
.gift {
  margin: 20px 0;
}

/* Gradient text effect */
.gift__g {
  text-transform: uppercase;
  font-size: 30px;
  @include xs{
    font-size: 20px;
  }
  font-weight: bold;
  color: transparent;
  background: linear-gradient(45deg, #f39c12, #8e44ad, #3498db); /* Gradient colors */
  -webkit-background-clip: text;
  background-clip: text;
  line-height: 1.4;
  padding: 10px;
  animation: textSlideIn 0.8s ease-in-out;
  letter-spacing: 1px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2); /* Light shadow to make the text pop */
}

/* Animation for the text sliding in */
@keyframes textSlideIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Styling for the result span */
.gift__g span {
  margin-top: 10px;
  font-size: 32px;
  @include xs{
    font-size: 20px;
  }
  font-weight: bold;
  color: white; /* Fallback for unsupported browsers */
  background: linear-gradient(45deg, #ff4d4d, #ff0000, #ff4d4d); /* Balanced red gradient */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; /* Ensures gradient visibility */
  padding: 8px 16px; /* Balanced padding for text */
  display: inline-block;
  border: 2px solid #ff4d4d; /* Border complementing the gradient */
  border-radius: 8px; /* Rounded corners for a smooth finish */
  text-shadow: 1px 1px 3px rgba(255, 0, 0, 0.3); /* Subtle shadow */
  box-shadow: 0px 0px 8px rgba(255, 0, 0, 0.2); /* Reduced outer glow */
  animation: pulseEffect 1.2s infinite alternate; /* Subtle pulsing effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth interaction */
}

/* Pulse animation for the span */
@keyframes pulseEffect {
  0% {
    transform: scale(1);
    box-shadow: 0px 0px 8px rgba(255, 0, 0, 0.2);
  }
  100% {
    transform: scale(1.05);
    box-shadow: 0px 0px 12px rgba(255, 0, 0, 0.4);
  }
}

/* Styling for the "You ran out of spins" message */
.hetluot {
  font-size: 18px;
  color: #e74c3c;
  font-weight: bold;
  margin-bottom: 20px;
}

.trangchu {
  font-size: 18px;
  color: #fff; /* White text for contrast */
  font-weight: bold;
  text-decoration: none; /* Remove underline by default */
  cursor: pointer;
  background: linear-gradient(45deg, #e74c3c, #c0392b); /* Red gradient background */
  padding: 8px 16px; /* Add padding for spacing */
  border-radius: 25px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(231, 76, 60, 0.2); /* Soft shadow matching the red */
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
  display: inline-block;
  text-align: center;
}

/* Hover effect */
.trangchu:hover {
  background: linear-gradient(45deg, #c0392b, #e74c3c); /* Reverse red gradient on hover */
  color: #fff; /* Keep text white */
  text-decoration: none; /* Remove underline */
  transform: translateY(-2px); /* Slightly raise the button */
  box-shadow: 0 6px 15px rgba(231, 76, 60, 0.3); /* Slightly increase shadow */
}
/* Styling for the info text asking for user details */
.text-info {
  margin-top: 20px;
}

.text-info__t {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

/* General modal slide-in animation */
@keyframes slideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Fade-in effect for the modal */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
