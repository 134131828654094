
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.my-button {
  background-color: #0074d9;
  /* Button background color */
  color: #fff;
  /* Text color */
  padding: 10px 20px;
  /* Padding inside the button */
  font-size: 16px;
  /* Font size */
  border: none;
  /* Remove the button border */
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
  /* Show a pointer cursor on hover */
}

.my-button:hover {
  background-color: #0056b3;
  /* Change background color on hover */
}



/* RotatingWheel.css */
.rotating-wheel {
  width: 200px;
  height: 200px;
  position: relative;
  animation: rotate 4s infinite linear;
  /* Adjust duration and animation type as needed */
}

.wheel {
  width: 100%;
  height: 100%;
  border: 2px solid #000;
  border-radius: 50%;
  position: relative;
  animation: rotate-reverse 4s infinite linear;
  /* Reverse animation for text alignment */
  transform: rotate(0deg);
}

.wheel-segment {
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
  background-color: #f00;
  /* Adjust segment colors as needed */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-reverse {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}


.circle-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.circle {
  width: 100px;
  height: 100px;
  border: 5px solid #3498db;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.spin {
  animation: spin 2s linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.dialog {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.dialog-content {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}


.download-table-xls-button{
  padding: 5px 10px;
  font-size: 16px;
  border: none;
  background-color: #0056b3;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
}



.text-a{
  font-size: 16px;
  color: #0056b3;

}
.text-bottom{
  font-size: 16px;
  margin-right: 8px;
  margin-left: 10px;
}


